import { useThemedStyleFunction, withExternalStyle } from "@miqmap/shared/src";
import { useCallback, useEffect } from "react";
import { TouchableOpacity, View, ViewProps } from "react-native"
import { shallow } from "zustand/shallow";
import { useDataStore, useStateStore } from "../../stores"
import { SideBar } from "../side-bar/side-bar.component"
import { SideBarDetail, SideBarList } from "./content";
import { sideBarPanelStyle } from "./side-bar-panel.style";
import { MenuIconOpen, MenuIconClose } from "../../assets";
import { createStackNavigator } from '@react-navigation/stack';

type SideBarPanelProps = {
    //
} & ViewProps;

const Stack = createStackNavigator();

const SideBarPanel = (props: SideBarPanelProps) => {
    const { style: styleProp } = props;

    const { selectedType, selectedId, sideBarOpen, setSideBarOpen } = useStateStore(state => state, shallow);

    // Open sidebar if closed when selecing something
    useEffect(() => {
        if (selectedId !== undefined && selectedType!== undefined) {
            setSideBarOpen(true);
        }
    }, [selectedId, selectedType, setSideBarOpen]);

    const hideSideBar = useCallback(() => {
        setSideBarOpen(false);
    }, []);
    const showSideBar = useCallback(() => {
        setSideBarOpen(true);
    }, []);

    const style = useThemedStyleFunction(sideBarPanelStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    return (
        <View style={containerStyle}>
            <SideBar open={sideBarOpen} maxWidth={420}>
                <View style={style.banner}>
                    <TouchableOpacity onPress={hideSideBar}>
                        <MenuIconClose style={style.closeIcon}/>
                    </TouchableOpacity>
                </View>

                <Stack.Navigator screenOptions={{headerShown: false, title: 'MiQMAP'}}>
                    <Stack.Screen name="list" component={SideBarList}/>
                    <Stack.Screen name="details" component={SideBarDetail}/>
                </Stack.Navigator>
            </SideBar>
            
            { !sideBarOpen && (
                <TouchableOpacity style={style.floatingTouchable} onPress={showSideBar}>
                    <MenuIconOpen style={style.openIcon}/>
                </TouchableOpacity>
            )}
        </View>
    )
};

export { SideBarPanel };