import { Image, TouchableOpacity, View, ViewProps } from "react-native";
import { withExternalStyle } from "../../utils";
import { useThemedStyleFunction } from "../theme-provider";
import { ThemedText } from "../themed-text";
import { imageRowStyle } from "./image-row.style";

type ImageRowProps = ViewProps & {
    images: string[];
    slots: number;
    onImagePress?: (index: number) => void;
};

const ImageRow = (props: ImageRowProps) => {
    const { style: styleProp, images, slots, onImagePress } = props;

    const excess = images.length - slots;

    const style = useThemedStyleFunction(imageRowStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    return (
        <View style={containerStyle}>
            {images.map((uri: string, index: number) => {
                // Only draw images within the slot range
                if (index >= slots) {
                    return;
                }

                const isLastVisible = index == slots - 1;
                const tileStyle = {
                    width: `${100 / slots}%`,
                    ...(isLastVisible ? {marginRight: 0} : {}),
                };
                
                return (
                    <TouchableOpacity style={[style.tile, tileStyle]} key={index} onPress={() => onImagePress?.(index)}>
                        <Image style={style.image} source={{uri}}/>

                        { isLastVisible && excess > 0 && (
                            <View style={style.tileOverlay}>
                                <ThemedText style={style.excess}>
                                    +{excess}
                                </ThemedText>
                            </View>
                        )}
                    </TouchableOpacity>
                );
            })}
        </View>
    );
};

export { ImageRow };