import { Theme } from "@miqmap/shared/src";
import { StyleSheet } from "react-native";

const headerStyle = (theme: Theme) => StyleSheet.create({
    container: {
        backgroundColor: theme.color.listEntryBackground,
        paddingLeft: theme.spacing.sides,
        paddingRight: theme.spacing.sides,
        paddingTop: theme.spacing.standard,
        paddingBottom: theme.spacing.standard,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    title: {
        fontSize: theme.fontSize.large,
    },
    icon: {
        width: theme.iconSize.large,
        height: theme.iconSize.large,
        color: theme.color.textPrimary,
    },
    hidden: {
        opacity: 0,
    },
});

export { headerStyle };