import { PathCategoryResponse, PathResponse, SnowmobilePathResponse, SnowmobileSubPathResponse, PoiResponse, PoiCategoryResponse, PathRequirements, SnowmobileRequirements, PoiRequirements } from "../interfaces";

export const getJson = <T>(baseUrl: string, path: string): Promise <T> => {
    return new Promise<T>((resolve, reject) => {
        return fetch(`${baseUrl}${path}`)
            .then(res => res.json())
            .then(json => resolve(json))
            .catch(error => reject(error));
    });
};

export const getPaths = (baseUrl: string, category?: string): Promise<PathResponse[]> => {
    const path = category ? `/paths/gps?filter={"pathCategoryId":"${category}"}` : '/paths/gps';
    return getJson<PathResponse[]>(baseUrl, path);
};

export const getPathCategories = (baseUrl: string): Promise<PathCategoryResponse[]> => {
    const path = '/paths/categories';
    return getJson<PathCategoryResponse[]>(baseUrl, path);
};

export const getSnowmobilePaths = (baseUrl: string): Promise<SnowmobilePathResponse[]> => {
    const path = '/snowmobile/trails';
    return getJson<SnowmobilePathResponse[]>(baseUrl, path);
};

export const getSnowmobileSubPaths = (baseUrl: string): Promise<SnowmobileSubPathResponse[]> => {
    const path = '/snowmobile/subpaths/gps';
    return getJson<SnowmobileSubPathResponse[]>(baseUrl, path);
};

export const getPois = (baseUrl: string, category?: string): Promise<PoiResponse[]> => {
    const path = category ? `/pois?filter={"pointOfInterestCategoryId":"${category}"}`: '/pois';
    return getJson<PoiResponse[]>(baseUrl, path);
};

export const getPoiCategories = (baseUrl: string): Promise<PoiCategoryResponse[]> => {
    const path = '/pois/categories';
    return getJson<PoiCategoryResponse[]>(baseUrl, path);
};

/**
 * Get the required data to render paths, alternatively filter by category
 */
export const getPathRequirements = (baseUrl: string, category?: string): Promise<PathRequirements> => {
    return new Promise<PathRequirements>((resolve, reject) => {
        Promise.all([
            getPaths(baseUrl, category),
            getPathCategories(baseUrl),
        ]).then((response: [PathResponse[], PathCategoryResponse[]]) => {
            const [paths, categories] = response;

            resolve({paths, categories});
        }).catch((error) => reject(error));
    });
};

/**
 * Gets the required data to render the snowmobile paths
 */
export const getSnowmobileRequirements = (baseUrl: string): Promise<SnowmobileRequirements> => {
    return new Promise<SnowmobileRequirements>((resolve, reject) => {
        Promise.all([
            getSnowmobilePaths(baseUrl),
            getSnowmobileSubPaths(baseUrl),
        ]).then((response: [SnowmobilePathResponse[], SnowmobileSubPathResponse[]]) => {
            const [paths, subPaths] = response;

            resolve({paths, subPaths});
        }).catch((error) => reject(error));
    });
};

/**
 * Gets the required data to render the pois, alternatively filter by category
 */
export const getPoiRequirements = (baseUrl: string, category?: string): Promise<PoiRequirements> => {
    return new Promise<PoiRequirements>((resolve, reject) => {
        Promise.all([
            getPois(baseUrl, category),
            getPoiCategories(baseUrl),
        ]).then((response: [PoiResponse[], PoiCategoryResponse[]]) => {
            const [pois, categories] = response;

            resolve({pois, categories});
        }).catch((error) => reject(error));
    });
};
