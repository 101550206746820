enum PathStatus {
    Open = 'open',
    Closed = 'closed',
};

enum FeatureType {
    Path = 'path',
    Poi = 'poi',
    SnowmobilePath = 'snowmobile-path',
    SnowmobileSubPath = 'snowmobile-subpath',
};

enum MapType {
    Map = 'map',
    Satelite = 'satelite',
};

enum PathCategoryId {
    CrossCountrySkiing = '1',
    Snowmobiling = '2',
    MountainBiking = '3',
    Hiking = '4',
};

enum PoiCategoryId {
    // Generic
    Restaurants = '1',
    Cafes = '2',
    Attractions = '3',
    Viewpoints = '4',
    Parkings = '5',

    // Branded
    Lifts = '6',
    Missions = '7',
    Treasures = '8',
};

export {
    PathStatus,
    FeatureType,
    MapType,
    PathCategoryId,
    PoiCategoryId,
};