import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import {
  SafeAreaView,
  StyleSheet,
} from 'react-native';
import './localization';
import {
  ThemeProvider,
} from '@miqmap/shared';
import { MapPanel, SideBarPanel } from './components';
import { fetchData } from './services';
import { NavigationContainer } from '@react-navigation/native';
import ImageViewer from 'react-simple-image-viewer';
import { useImageViewerStore } from './stores';
import { shallow } from 'zustand/shallow';

interface AppProps {
  baseUrl: string;
};

const App = (props: AppProps) => {
  const {baseUrl} = props;

  // Fetch data based on filters
  useEffect(() => {
    fetchData(baseUrl)
    .then(() => {
      console.log('finished loading data');
    });
  }, []);

  const {visible, images, current, hideImageViewer} = useImageViewerStore((state) => state, shallow);

  return (
    <NavigationContainer>
      <ThemeProvider>
        <SafeAreaView style={styles.root}>
          <SideBarPanel style={styles.sideBar}/>
          <MapPanel style={styles.map}/>

          { visible && 
            <ImageViewer src={images} currentIndex={current} onClose={hideImageViewer}/>
          }
        </SafeAreaView>
      </ThemeProvider>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  root: {
    height: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "white",
    overflow: "hidden",
  },
  map: {
    flex: 1,
  },
  sideBar: {
    zIndex: 1,
  }
});

export default App;