import { Theme } from "@miqmap/shared/src";
import { StyleSheet } from "react-native";

const sideBarContentStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: theme.color.listBackground,
    },

    brightContainer: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: theme.color.listEntryBackground,
    },

    list: {
        flex: 1,
    },

    marginTop: {
        marginTop: theme.spacing.standard,
    },

    marginBottom: {
        marginBottom: theme.spacing.standard,
    }
});

export { sideBarContentStyle };