import { Platform, TouchableOpacity, View } from 'react-native';
import { useTranslation } from "react-i18next";
import { useThemedStyleFunction } from "../theme-provider";
import { useCallback } from 'react';
import { ThemedText } from '../themed-text';
import { Poi, PoiCategory } from '../../interfaces';
import { detailsContentStyle } from './details-content.style';
import { share } from '../../utils';
import { ShareIcon } from '../../assets';
import { getTranslatedProperty } from '../../utils/localization.utils';
import { ImageRow } from '../image-row';

type PoiDetailsContentProps = Poi & {
    category: PoiCategory,
    onImagePress?: (images: string[], index: number) => void,
};

const PoiDetailsContent = (props: PoiDetailsContentProps) => {
    const {category, areas, url, imageUrls, onImagePress} = props;

    const [t] = useTranslation();

    const categoryName = getTranslatedProperty('name', category);

    const formattedAreas = areas.map((area => getTranslatedProperty('name', area)?.toLowerCase()));
    const metadata = [categoryName, ...formattedAreas].join(', ');

    const description = getTranslatedProperty('description', props);

    const handleUrl = useCallback(() => {
        if (url === undefined || !url.length) {
            return;
        }
        if (Platform.OS === 'web') {
            window.open(url, '_newtab');
        }
    }, [url]);

    const handleOnImagePress = useCallback((index: number) => {
        onImagePress?.(imageUrls, index);
    }, [imageUrls]);

    const style = useThemedStyleFunction(detailsContentStyle);

    return (
        <View style={style.container}>
            <View style={style.row}>
                <ThemedText style={[style.standard, style.secondary]}>{metadata}</ThemedText>
            </View>

            { url && (
                <View style={[style.row, style.paragraph]}>
                    <TouchableOpacity style={style.secondaryHeader} onPress={handleUrl}>
                        <ThemedText style={[style.medium, style.link]} type='bold' numberOfLines={1} ellipsizeMode={'tail'}>{url}</ThemedText>
                    </TouchableOpacity>
                </View>
            )}

            { imageUrls?.length &&
                <View style={style.paragraph}>
                    <ImageRow slots={4} images={imageUrls} onImagePress={handleOnImagePress}/>
                </View>
            }

            { description &&
                <View style={style.paragraph}>
                    <ThemedText style={[style.standard, style.primary]}>{description}</ThemedText>
                </View>
            }
        </View>
    )
};

export { PoiDetailsContent };