import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { View } from 'react-native';
import MapComponent from '@miqmap/map-content/src/Map';
import { FeatureType, MapType } from '@miqmap/map-content/src/enums';
import { GpsCoordinate, MapFunctions, MapProps, PathCategories, PathGpsCoordinates, Paths, PoiCategories, Pois, SnowmobilePaths, SnowmobileSubPaths } from '../../interfaces';
import { FeatureGroup } from '@miqmap/map-content/src/interfaces';

const MapBase = (props: MapProps, ref: any) => {
    const {style: styleProp, onLoad, onMessage} = props;
    const componentRef = useRef<any>();

    useImperativeHandle(ref, (): MapFunctions => ({
        setPois: (pois: Pois, categories: PoiCategories) => componentRef.current?.setPois(pois, categories),
        setPaths: (paths: Paths, categories: PathCategories, coordinates: PathGpsCoordinates) => componentRef.current?.setPaths(paths, categories, coordinates),
        setSnowmobilePaths: (paths: SnowmobilePaths, subPaths: SnowmobileSubPaths) => componentRef.current?.setSnowmobilePaths(paths, subPaths),
        setMapType: (type: MapType) => componentRef.current?.setMapType(type),
        setFilters: (filters: FeatureGroup[]) => componentRef.current?.setFilters(filters),
        setFocus: (focus: FeatureGroup[], refocus: boolean = true) => componentRef.current?.setFocus(focus, refocus),
        setSelected: (type?: FeatureType, id?: string) => componentRef.current?.setSelected(type, id),
        setHighlighted: (type?: FeatureType, id?: string) => componentRef.current?.setHighlighted(type, id),
        setGpsPosition: (position: GpsCoordinate) => componentRef.current?.setGpsPosition(position),
        centerGps: () => componentRef.current?.centerGps(),
        adjustZoom: (amount: number) => componentRef.current?.adjustZoom(amount),
    }));

    useEffect(() => {
        onLoad?.();
    }, []);

    const handleMessage = (msg: string) => {
        const data = JSON.parse(msg);
        if (data) {
            onMessage?.(data);
        }
    };

    return (
        <View style={styleProp}>
            <MapComponent style={{ width: '100%', height: '100%' }} ref={(ref: any) => componentRef.current = ref} onMessage={(msg: string) => handleMessage(msg)}/>
        </View>
    );
};

const Map = forwardRef(MapBase);
export { Map };