import { Theme } from './theme.interface';
import { colors } from '../../utils';

export const defaultTheme: Theme = {
    color: {
        background: colors.white,
        listBackground: colors.graytone1,
        listEntryBackground: colors.white,
        listBackgroundInverted: colors.neutralColor300,
        textPrimary: colors.neutralColor900,
        textSecondary: colors.neutralColor400,
        textInverted: colors.white,
        textLink: colors.primaryColor500,

        graphBackground: colors.neutralColor50,
        graphForeground: colors.primaryColor500,
        graphPrimaryOverlay: colors.neutralColor300,
        graphSecondaryOverlay: colors.white,

        mapIcons: colors.neutralColor500,
    },
    units: {
        vw: 100,
        vh: 100,
        base: 10,
    },
    spacing: {
        sliver: 2,
        half: 8,
        standard: 16,
        semiDouble: 24,
        double: 32,
        medium: 40,
        sides: 24,
    },
    borderRadius: {
        standard: 8,
        medium: 12,
        large: 16,
    },
    iconSize: {
        extraSmall: 8,
        small: 12,
        standard: 14,
        medium: 16,
        large: 24,
    },
    fontSize: {
        small: 12,
        standard: 14,
        medium: 16,
        large: 18,
    },
};