import { colors } from '@miqmap/shared/src/utils/variables';

const defaultColor = colors.neutralColor500;
const brandColor = colors.primaryColor500;

const COLORS = {
    PATHS: {
        CROSS_COUNTRY_SKIING: defaultColor,
        SNOWMOBILING: defaultColor,
        MOUNTAIN_BIKING: defaultColor,
        HIKING: brandColor,
        ENDCAP: brandColor,
        NO_CATEGORY: defaultColor,
    },
    POIS: {
        NO_CATEGORY: defaultColor,

        // Generic
        RESTAURANTS: defaultColor,
        ATTRACTIONS: defaultColor,
        LOOKOUTS: defaultColor,
        PARKING: defaultColor,

        // Branded
        LIFTS: brandColor,
        MISSIONS: brandColor,
        TREASURES: brandColor,

        // Misc
        CABINS: defaultColor,
        STORES: defaultColor,
        BIKE_WASH: defaultColor,
        SNOWMOBILE_RENTAL: defaultColor,
        SPORTS_RENTAL: defaultColor,
        TOILETS: defaultColor,
        SKI_WAXING_SHED: defaultColor,
        WARNING: colors.errorColor500,
    }
};

export { COLORS };