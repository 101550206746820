import { ReactComponent as MenuIconOpen } from './icons/menu-icon-open.svg';
import { ReactComponent as MenuIconClose } from './icons/menu-icon-close.svg';
import { ReactComponent as MenuIconLeft } from './icons/menu-icon-left.svg';
import { ReactComponent as MenuIconSearch } from './icons/menu-icon-search.svg';
import { ReactComponent as Logo } from './logo-miqmap.svg';

const splashBg = require('./splash-bg.jpg');

export {
    MenuIconOpen,
    MenuIconClose,
    MenuIconLeft,
    MenuIconSearch,
    Logo,
    splashBg,
};