import React, {PropsWithChildren, useEffect, useState, useMemo, useContext} from 'react';
import {Platform, Dimensions} from 'react-native';
import { defaultTheme } from './theme.defaults';
import { Theme } from './theme.interface';

const ThemeContext = React.createContext(defaultTheme);

const ThemeProvider = (props: PropsWithChildren) => {
    const windowSize = Dimensions.get('window');
    const [width, setWidth] = useState(windowSize.width);
    const [height, setHeight] = useState(windowSize.height);

    const memoizedTheme = useMemo(() => {
        const vw = width * 0.01;
        const vh = height * 0.01;
        const base = Platform.OS === 'web'
            ? 10            // 10 pixels
            : vw * 2.666;   // Equivalent of 10 pixels on a 375 pixel wide screen

        return {
            ...defaultTheme,
            units: {
                vw,
                vh,
                base,
            },
            spacing: {
                sliver: base * .2,
                half: base * .8,
                standard: base * 1.6,
                semiDouble: base * 2.4,
                double: base * 3.2,
                medium: base * 4,
                sides: base * 2.4,
            },
            borderRadius: {
                standard: base * .8,
                medium: base * 1.2,
                large: base * 1.6,
            },
            iconSize: {
                extraSmall: base * .8,
                small: base * 1.2,
                standard: base * 1.4,
                medium: base * 1.6,
                large: base * 2.4,
            },
            fontSize: {
                small: base * 1.2,
                standard: base * 1.4,
                medium: base * 1.6,
                large: base * 1.8,
            },
        };
    }, [width, height]);

    useEffect(() => {
        Dimensions.addEventListener('change', ({window}) => {
            setWidth(window.width);
            setHeight(window.height);
        });
    }, []);

    return (
        <ThemeContext.Provider value={memoizedTheme}>
            {props.children}
        </ThemeContext.Provider>
    );
}

type Generator<T extends {}> = (theme: Theme) => T;
const useThemedStyleFunction = <T extends {}>(func: Generator<T>) => {
    const theme = useContext(ThemeContext);

    return useMemo(() => func(theme), [func, theme]);
};

export {ThemeContext, ThemeProvider, useThemedStyleFunction};