import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SnowmobileSubPath } from '../../interfaces';
import { getStatusColor, getStatusText } from '../../utils';
import { useThemedStyleFunction } from '../theme-provider';
import { ThemedText } from '../themed-text';
import { listContentStyle } from './list-content.style';
import { getTranslatedProperty } from '../../utils/localization.utils';
import { PathSnowmobiling } from '../../assets';

type SnowmobileSubPathListContentProps = SnowmobileSubPath & {
    //
};

const SnowmobileSubPathListContent = (props: SnowmobileSubPathListContentProps) => {
    const {status, groomedAt, zone} = props;

    const [t] = useTranslation();

    const name = getTranslatedProperty('name', props);

    const statusText = getStatusText(t, status, groomedAt);
    const statusColor = getStatusColor(status, groomedAt);
    
    const formattedZone = t(zone);

    const style = useThemedStyleFunction(listContentStyle);

    return (
        <View style={style.container}>
            <PathSnowmobiling style={style.categoryIcon}/>
            <View style={style.content}>
                <View style={[style.flexRow, style.row]}>
                    <View style={style.flexRow}>
                        <ThemedText type="bold" style={style.title}>{ name }</ThemedText>
                    </View>
                </View>
                <View style={[style.flexRow, style.row]}>
                    <ThemedText type="light" style={style.subtitle}>{ formattedZone }</ThemedText>
                    <ThemedText style={[style.status, {color: statusColor}]}>{ statusText }</ThemedText>
                </View>
            </View>
        </View>
    );
};

export { SnowmobileSubPathListContent };