import { ImageStore, TouchableOpacity, View } from 'react-native';
import { useTranslation } from "react-i18next";
import { getDifficultyIcon, getDifficultyName, getDistanceTo, getElevationSpan, getMaxElevation, getMinElevation, getStatusColor, getStatusText, toKilometerString } from "../../utils";
import { useThemedStyleFunction } from "../theme-provider";
import { ElevationGraph } from '../elevation-graph';
import { useCallback, useState } from 'react';
import { ThemedText } from '../themed-text';
import { Path, PathCategory, PathGpsCoordinate } from '../../interfaces';
import { detailsContentStyle } from './details-content.style';
import { share } from '../../utils';
import { ShareIcon, SmallArrowBack, SmallArrowForward } from '../../assets';
import { getTranslatedProperty } from '../../utils/localization.utils';
import { ImageRow } from '../image-row';

type PathDetailsContentProps = Path & {
    gpsCoordinates: PathGpsCoordinate[],
    category: PathCategory,
    closestIndex: number,
    onImagePress?: (images: string[], index: number) => void,
};

const PathDetailsContent = (props: PathDetailsContentProps) => {
    const {gpsCoordinates, closestIndex, category, difficulty, length, status, groomedAt, tags, imageUrls, onImagePress} = props;

    const [t] = useTranslation();
    const [graphReversed, setGraphReversed] = useState(false);
    
    const DifficultyIcon = (props: any) => getDifficultyIcon(difficulty, props);
    const difficultyName = getDifficultyName(t, difficulty, false);
    
    const formattedLength = toKilometerString(length);
    
    const statusText = getStatusText(t, status);
    const statusColor = getStatusColor(status);

    // Left and right distance fields (distance traveled and total length)
    const hasLocation = closestIndex !== -1;
    const totalDistance = getDistanceTo(gpsCoordinates, gpsCoordinates.length - 1);
    const distanceTraveled = hasLocation
        ? getDistanceTo(gpsCoordinates, graphReversed ? gpsCoordinates.length - 1 - closestIndex : closestIndex) : 0;
    const leftText = hasLocation
        ? toKilometerString(distanceTraveled) : '';
    const rightText = hasLocation
        ? toKilometerString(totalDistance - distanceTraveled) : toKilometerString(totalDistance);

    const elevation = getElevationSpan(getMinElevation(gpsCoordinates), getMaxElevation(gpsCoordinates));
    const formattedElevation = `${elevation} m`;

    const formattedTags = tags.map((tag => getTranslatedProperty('name', tag)?.toLowerCase()));
    const metadata = [difficultyName, ...formattedTags].join(', ');

    const description = getTranslatedProperty('description', props);

    const categoryName = getTranslatedProperty('name', category);

    const handleReverse = useCallback(() => {
        setGraphReversed(!graphReversed);
    }, [graphReversed, setGraphReversed]);

    const handleShare = useCallback(() => {
        const message = `(${difficultyName.toLowerCase()})`;
        const url = 'http://www.example.com';

        share(message, url);
    }, [share, difficultyName]);

    const handleOnImagePress = useCallback((index: number) => {
        onImagePress?.(imageUrls ?? [], index);
    }, [imageUrls]);
    
    // Placeholder data
    const secondaryHeader = "New secondary header here, may take up two to three lines";

    const style = useThemedStyleFunction(detailsContentStyle);

    return (
        <View style={style.container}>
            <View style={style.row}>
                <DifficultyIcon style={style.difficultyIcon}/>
                <ThemedText style={[style.standard, style.secondary]}>{metadata}</ThemedText>
            </View>
            <View style={style.row}>
                <ThemedText style={[style.standard, style.primary]} type='bold'>{formattedLength}</ThemedText>
                <ThemedText style={[style.standard, style.primary]}> {categoryName}, </ThemedText>
                <ThemedText style={[style.standard, style.primary]} type='bold'>{formattedElevation}</ThemedText>
                <ThemedText style={[style.standard, style.primary]}> altitude - </ThemedText>
                <ThemedText style={[style.standard, style.capitalize, {color: statusColor}]} type='bold'>{statusText}</ThemedText>
            </View>

            <View style={style.paragraph}>
                <ElevationGraph gpsCoords={gpsCoordinates} reversed={graphReversed} closestIndex={closestIndex}/>
                <View style={style.graphInfo}>
                    <ThemedText style={[style.standard, style.secondary, style.leftGraphText]} type='bold'>{leftText}</ThemedText>
                    <TouchableOpacity style={style.row} onPress={handleReverse}>
                        <SmallArrowBack style={style.arrowIcon}/>
                        <ThemedText style={[style.standard, style.link, style.capitalize]}>{t('switchDirection')}</ThemedText>
                        <SmallArrowForward style={style.arrowIcon}/>
                    </TouchableOpacity>
                    <ThemedText style={[style.standard, style.secondary, style.rightGraphText]}>{rightText}</ThemedText>
                </View>
            </View>

            { imageUrls?.length &&
                <View style={style.paragraph}>
                    <ImageRow slots={4} images={imageUrls} onImagePress={handleOnImagePress}/>
                </View>
            }

            { description &&
                <View style={style.paragraph}>
                    <ThemedText style={[style.standard, style.primary]}>{description}</ThemedText>
                </View>
            }
        </View>
    )
};

export { PathDetailsContent };