import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const detailsContentStyle = (theme: Theme) => StyleSheet.create({
    container: {
        paddingLeft: theme.spacing.sides,
        paddingRight: theme.spacing.sides,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing.sliver,
    },
    difficultyIcon: {
        width: theme.iconSize.medium,
        height: theme.iconSize.medium,
        marginRight: theme.spacing.half,
    },
    graphInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.spacing.half,
    },
    arrowIcon: {
        color: theme.color.textLink,
        width: theme.iconSize.small,
        height: theme.iconSize.small,
        marginLeft: theme.spacing.half,
        marginRight: theme.spacing.half,
    },
    leftGraphText: {
        width: '20%',
        textAlign: 'left',
    },
    rightGraphText: {
        width: '20%',
        textAlign: 'right',
    },
    share: {
        paddingLeft: theme.spacing.standard,
        marginLeft: 'auto',
        marginBottom: 'auto',
    },
    shareIcon: {
        color: theme.color.textLink,
        width: theme.iconSize.large,
        height: theme.iconSize.large,
        marginLeft: theme.spacing.half,
    },
    secondaryHeader: {
        flexShrink: 1,
        flexWrap: 'wrap',
    },
    paragraph: {
        marginTop: theme.spacing.standard,
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    standard: {
        fontSize: theme.fontSize.standard,
    },
    medium: {
        fontSize: theme.fontSize.medium,
    },
    link: {
        color: theme.color.textLink,
    },
    primary: {
        color: theme.color.textPrimary,
    },
    secondary: {
        color: theme.color.textSecondary,
    },
});

export { detailsContentStyle }