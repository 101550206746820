import { COLORS } from './app.constants';

const MAP_SRC = 'https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/token/016549e5-cc39-3abe-a778-467006e7cce1/?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topowebb&STYLE=default&TILEMATRIXSET=3857&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=image%2Fpng';
const SAT_SRC = 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

const ICONS = {
    PATHS: {
        CROSS_COUNTRY_SKIING: '',
        SNOWMOBILING: '',
        MOUNTAIN_BIKING: '',
        HIKING: 'openlayers/icons/paths/path-hiking.svg',
        ENDCAP: 'openlayers/icons/paths/path-endcap.svg',
    },
    POIS: {
        // Generic
        ATTRACTIONS: 'openlayers/icons/pois/poi-attractions.svg',
        PARKING: 'openlayers/icons/pois/poi-parking.svg',
        RESTAURANTS: 'openlayers/icons/pois/poi-restaurants.svg',
        LOOKOUTS: 'openlayers/icons/pois/poi-viewpoints.svg',

        // Branded
        MISSIONS: 'openlayers/icons/pois/poi-missions.svg',
        TREASURES: 'openlayers/icons/pois/poi-treasures.svg',
        LIFTS: 'openlayers/icons/pois/poi-lifts.svg',

        // Misc
        STORES: '',
        BIKE_WASH: '',
        BREAK_CABIN: '',
        SNOWMOBILE_RENTAL: '',
        SPORTS_RENTAL: '',
        TOILETS: '',
        SKI_WAXING_SHED: '',
        WARNINGS: '',
    },
    GPS_POSITION: 'openlayers/gps-position.svg',
    DIRECTION_ARROW: 'openlayers/direction-arrow.svg',
};

const PROPS = {
    PATHS: {
        CATEGORY_4: {
            color: COLORS.PATHS.HIKING,
            icon: ICONS.PATHS.HIKING,
            closedIcon: ICONS.PATHS.HIKING,
        },
    },
    POIS: {
        // Generic
        POI_CATEGORY_1: {
            color: COLORS.POIS.RESTAURANTS,
            icon: ICONS.POIS.RESTAURANTS,
            closedIcon: ICONS.POIS.RESTAURANTS,
        },
        POI_CATEGORY_2: {
            color: COLORS.POIS.RESTAURANTS,
            icon: ICONS.POIS.RESTAURANTS,
            closedIcon: ICONS.POIS.RESTAURANTS,
        },
        POI_CATEGORY_3: {
            color: COLORS.POIS.ATTRACTIONS,
            icon: ICONS.POIS.ATTRACTIONS,
            closedIcon: ICONS.POIS.ATTRACTIONS,
        },
        POI_CATEGORY_4: {
            color: COLORS.POIS.LOOKOUTS,
            icon: ICONS.POIS.LOOKOUTS,
            closedIcon: ICONS.POIS.LOOKOUTS,
        },
        POI_CATEGORY_5: {
            color: COLORS.POIS.PARKING,
            icon: ICONS.POIS.PARKING,
            closedIcon: ICONS.POIS.PARKING,
        },

        // Branded
        POI_CATEGORY_6: {
            color: COLORS.POIS.LIFTS,
            icon: ICONS.POIS.LIFTS,
            closedIcon: ICONS.POIS.LIFTS,
        },
        POI_CATEGORY_7: {
            color: COLORS.POIS.MISSIONS,
            icon: ICONS.POIS.MISSIONS,
            closedIcon: ICONS.POIS.MISSIONS,
        },
        POI_CATEGORY_8: {
            color: COLORS.POIS.TREASURES,
            icon: ICONS.POIS.TREASURES,
            closedIcon: ICONS.POIS.TREASURES,
        },

        // Misc
        POI_CATEGORY_9: {
            color: COLORS.POIS.STORES,
            icon: ICONS.POIS.STORES,
            closedIcon: ICONS.POIS.STORES,
        },
        POI_CATEGORY_10: {
            color: COLORS.POIS.BIKE_WASH,
            icon: ICONS.POIS.BIKE_WASH,
            closedIcon: ICONS.POIS.BIKE_WASH,
        },
        POI_CATEGORY_11: {
            color: COLORS.POIS.CABINS,
            icon: ICONS.POIS.BREAK_CABIN,
            closedIcon: ICONS.POIS.BREAK_CABIN,
        },
        POI_CATEGORY_12: {
            color: COLORS.POIS.SNOWMOBILE_RENTAL,
            icon: ICONS.POIS.SNOWMOBILE_RENTAL,
            closedIcon: ICONS.POIS.SNOWMOBILE_RENTAL,
        },
        POI_CATEGORY_13: {
            color: COLORS.POIS.SPORTS_RENTAL,
            icon: ICONS.POIS.SPORTS_RENTAL,
            closedIcon: ICONS.POIS.SPORTS_RENTAL,
        },
        POI_CATEGORY_14: {
            color: COLORS.POIS.TOILETS,
            icon: ICONS.POIS.TOILETS,
            closedIcon: ICONS.POIS.TOILETS,
        },
        POI_CATEGORY_15: {
            color: COLORS.POIS.SKI_WAXING_SHED,
            icon: ICONS.POIS.SKI_WAXING_SHED,
            closedIcon: ICONS.POIS.SKI_WAXING_SHED,
        },
        POI_CATEGORY_16: {
            color: COLORS.POIS.WARNING,
            icon: ICONS.POIS.WARNINGS,
            closedIcon: ICONS.POIS.WARNINGS,
        },
    },
};

const OBJECT_TYPE = 'objectType';
const OBJECT_ID = 'objectId';

export {
    MAP_SRC,
    SAT_SRC,
    ICONS,
    PROPS,
    OBJECT_TYPE,
    OBJECT_ID,
};