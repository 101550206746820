import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const listContentStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingVertical: theme.spacing.half,
        paddingLeft: theme.spacing.half,
        paddingRight: theme.spacing.standard,
        height: theme.units.base * 6.4,
        alignContent: 'center',
    },
    categoryIcon: {
        aspectRatio: 1 / 1,
        width: 'auto',
        height: '100%',
        marginRight: theme.spacing.half,
        color: theme.color.mapIcons,
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        fontSize: theme.fontSize.medium,
        color: theme.color.textPrimary,
    },
    subtitle: {
        fontSize: theme.fontSize.standard,
        color: theme.color.textPrimary,
        opacity: 0.56,
    },
    length: {
        fontSize: theme.fontSize.medium,
        color: theme.color.textSecondary,
    },
    status: {
        fontSize: theme.fontSize.standard,
        color: theme.color.textSecondary,
    },
    difficultyIcon: {
        height: theme.iconSize.medium,
        width: theme.iconSize.medium,
        marginRight: theme.spacing.half,
    },
    flexRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    row: {
        justifyContent: 'space-between',
        marginBottom: theme.spacing.sliver,
    }
});

export { listContentStyle };