import React, { useCallback } from "react";
import { ThemedText, useThemedStyleFunction, withExternalStyle } from "@miqmap/shared/src";
import { View, ViewProps, TouchableOpacity } from "react-native";
import { headerStyle } from "./header.style";
import { MenuIconLeft, MenuIconSearch } from "../../assets";

type HeaderProps = ViewProps & {
    title?: string;
    onBack?: () => void;
};

const Header = (props: HeaderProps) => {
    const { style: styleProp, title, onBack } = props;

    const handleOnBack = useCallback(() => {
        onBack?.();
    }, [onBack]);

    const style = useThemedStyleFunction(headerStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    return (
        <View style={containerStyle}>
            { onBack &&
                <TouchableOpacity disabled={!onBack} onPress={handleOnBack}>
                    <MenuIconLeft style={style.icon}/>
                </TouchableOpacity>
            }

            <ThemedText style={style.title} type={'bold'}>{ title }</ThemedText>

            <TouchableOpacity>
                <MenuIconSearch style={style.icon}/>
            </TouchableOpacity>
        </View>
    )
};

export { Header };