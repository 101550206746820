import { View } from 'react-native';
import { useTranslation } from "react-i18next";
import { getStatusColor, getStatusText } from "../../utils";
import { useThemedStyleFunction } from "../theme-provider";
import { ThemedText } from '../themed-text';
import { PathCategory, SnowmobileSubPath } from '../../interfaces';
import { detailsContentStyle } from './details-content.style';
import { getTranslatedProperty } from '../../utils/localization.utils';

type SnowmobileSubPathDetailsContentProps = SnowmobileSubPath & {
    category: PathCategory,
};

const SnowmobileSubPathDetailsContent = (props: SnowmobileSubPathDetailsContentProps) => {
    const {category, status, groomedAt, zone} = props;

    const [t] = useTranslation();
    
    const statusText = getStatusText(t, status, groomedAt);
    const statusColor = getStatusColor(status, groomedAt);

    const categoryName = getTranslatedProperty('name', category);
    const formattedZone = t(zone);

    const style = useThemedStyleFunction(detailsContentStyle);

    return (
        <View style={style.container}>
            <View style={style.row}>
                <ThemedText style={[style.standard, style.secondary]}>{ formattedZone }</ThemedText>
            </View>
            <View style={style.row}>
                <ThemedText style={[style.standard, style.primary]}>{ categoryName }</ThemedText>
                <ThemedText style={[style.standard, style.primary]}> - </ThemedText>
                <ThemedText style={[style.standard, style.capitalize, {color: statusColor}]} type='bold'>{ statusText }</ThemedText>
            </View>
        </View>
    )
};

export { SnowmobileSubPathDetailsContent };