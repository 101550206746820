import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const listStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: theme.spacing.standard,
        paddingBottom: theme.spacing.standard,
        paddingLeft: theme.spacing.sides,
        paddingRight: theme.spacing.sides,
        backgroundColor: theme.color.listBackground,
    }
});

export { listStyle };