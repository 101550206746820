import React from 'react';
import { View, ViewProps } from 'react-native';
import { withExternalStyle } from '../../utils';
import { useThemedStyleFunction } from '../theme-provider';
import { listEntryStyle } from './list-entry.style';

const ListEntry = (props: ViewProps) => {
    const {style: styleProp} = props;

    const style = useThemedStyleFunction(listEntryStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    return (
        <View {...props} style={containerStyle}>
            {props.children}
        </View>
    )
};

export { ListEntry };