import { useCallback, useEffect, useState } from 'react';
import { ScrollView, View, ViewProps } from 'react-native';
import { Filters, List } from '../../components';
import { useThemedStyleFunction } from '../theme-provider';
import { usePrevious, withExternalStyle } from '../../utils';
import { ActiveFilters, Filter } from '../../interfaces';
import { filteredListStyle } from './filtered-list.style';
import { isEqual } from 'lodash';

type FilteredListProps = ViewProps & {
    emptyText: string;
    dataProviders: any[];
    filters: Filter[];
    onPress?: (providerIndex: number, entryIndex: number) => void,
    onFiltered?: (entries: any[]) => void,
}

const FilteredList = (props: FilteredListProps) => {
    const {style: styleProp, emptyText, dataProviders, filters, onPress, onFiltered} = props;

    const [filteredDataProviders, setFilteredDataProviders] = useState(dataProviders);

    const previousDataProviders = usePrevious(dataProviders);
    useEffect(() => {
        if (!isEqual(previousDataProviders, dataProviders)) {
            setFilteredDataProviders(dataProviders);
            onFiltered?.(dataProviders);
        }
    });

    const style = useThemedStyleFunction(filteredListStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    const handleActiveFilters = useCallback((activeFilters: ActiveFilters) => {
        const newDataProviders: any[] = [];

        dataProviders.forEach((provider) => {
            const newDataProvider = {
                component: provider.component,
                entries: [] as any[],
            };

            provider.entries.forEach((entry: any) => {
                for (let i = 0; i < filters.length; i++) {
                    const identifier = filters[i].identifier;
                    const evaluator = filters[i].evaluator;
                    
                    if (!evaluator(entry, activeFilters[identifier] as string)) {
                        return;
                    }
                }
                newDataProvider.entries.push({...entry});
            });
            newDataProviders.push(newDataProvider);
        });

        setFilteredDataProviders(newDataProviders);
        onFiltered?.(newDataProviders);
    }, [dataProviders, setFilteredDataProviders]);

    const handleOnPress = useCallback((providerIndex: number, entryIndex: number) => {
        const data = filteredDataProviders[providerIndex].entries[entryIndex];

        // Find corresponding data in source providers since we're internally handling a filtered variant
        for (let pI = 0; pI < dataProviders.length; pI++) {
            const provider = dataProviders[pI];

            for (let eI = 0; eI < provider.entries.length; eI++) {
                if (isEqual(provider.entries[eI], data)) {
                    onPress?.(pI, eI);
                    break;
                }
            }
        }
    }, [filteredDataProviders, dataProviders]);

    return (
        <View style={containerStyle}>
            { filters?.length
                ? <Filters filters={filters} onChange={handleActiveFilters}/>
                : null
            }
            <ScrollView>
                <List onPress={handleOnPress} emptyText={emptyText} dataProviders={filteredDataProviders}/>
            </ScrollView>
        </View>
    );
};

export { FilteredList };