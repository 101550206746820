import React from 'react';
import moment from 'moment';
import { colors } from './variables';
import { PathDifficulty, PathStatus } from '../enums';
import { DifficultyVeryEasy, DifficultyEasy, DifficultyMedium, DifficultyHard, PathCrossCountrySkiing, PathSnowmobiling, PathMountainBiking, PathHiking, PoiRestaurants, PoiAttractions, PoiViewpoints, PoiParking, PoiLifts, PoiMissions, PoiTreasures } from '../assets';
import { View } from 'react-native';
import { PathCategoryId, PoiCategoryId } from '@miqmap/shared/src';

const FRESHLY_GROOMED_CUTOFF = 1;

enum GroomedState {
    Grooming = 'grooming',
    FreshlyGroomed = 'freshlyGroomed',
    GroomedToday = 'groomedToday',
    GroomedYesterday = 'groomedYesterday',
    GroomedWithinWeek = 'groomedWithinWeek',
    GroomedPastWeek = 'groomedPastWeek',
};
  
type GroomedStateReturnType = {
    state: string;
    hoursPassed?: number;
    weekday?: number;
};

const formatLength = (length: number): string => `${length.toFixed(1).replace('.', ',')} km`;

const getDifficultyIcon = (difficulty: string, props?: any) => {
    switch (difficulty) {
        case PathDifficulty.VeryEasy:
            return <DifficultyVeryEasy {...props} />;

        case PathDifficulty.Easy:
            return <DifficultyEasy {...props} />;

        case PathDifficulty.Medium:
            return <DifficultyMedium {...props} />;

        case PathDifficulty.Hard:
            return <DifficultyHard {...props} />;

        default:
            return <View {...props}></View>;
    }
};

const getDifficultyName = (t: (id: string, options?: any) => string, difficulty: string, plural: boolean): string => {
    switch (difficulty) {
        case PathDifficulty.VeryEasy:
            return plural ? t('veryEasyPlural') : t('veryEasy');

        case PathDifficulty.Easy:
            return plural ? t('easyPlural') : t('easy');

        case PathDifficulty.Medium:
            return plural ? t('mediumPlural') : t('medium');

        case PathDifficulty.Hard:
            return plural ? t('hardPlural') : t('hard');

        default:
            return '';
    }
};

const getGroomedState = (groomedAt: string): GroomedStateReturnType => {
    const now = moment();
    const date = moment(groomedAt);

    const timePassed = moment.duration(now.diff(date));
    const hoursPassed = Math.floor(timePassed.asHours());
    const weekday = date.day();

    if (hoursPassed < FRESHLY_GROOMED_CUTOFF) {
        return {state: GroomedState.Grooming};
    }

    if (date.isSame(now.clone().startOf('day'), 'd')) {
        return {state: GroomedState.GroomedToday, hoursPassed, weekday};
    }

    if (date.isSame(now.clone().subtract(1, 'days').startOf('day'), 'd')) {
        return {state: GroomedState.GroomedYesterday, hoursPassed, weekday};
    }
    return {state: GroomedState.GroomedPastWeek, hoursPassed, weekday};
};

const getStatusText = (t: (id: string, options?: any) => string, status: string, groomedAt?: string | null): string => {
    switch (status) {
        case PathStatus.Open:
            if (groomedAt) {
                const groomedState = getGroomedState(groomedAt);

                const weekdays = [
                    t('abbreviatedSunday'),
                    t('abbreviatedMonday'),
                    t('abbreviatedTuesday'),
                    t('abbreviatedWednesday'),
                    t('abbreviatedThursday'),
                    t('abbreviatedFriday'),
                    t('abbreviatedSaturday'),
                ];

                switch (groomedState.state) {
                    case GroomedState.Grooming:
                        return t('groomedStatusCurrently');

                    case GroomedState.FreshlyGroomed:
                    case GroomedState.GroomedToday:
                        return t('groomedStatusPassed', {hoursPassed: groomedState.hoursPassed});

                    case GroomedState.GroomedYesterday:
                        return t('groomedStatusYesterday');

                    case GroomedState.GroomedWithinWeek:
                        return t('groomedStatusWeekday', {weekday: weekdays[groomedState.weekday || 0]});

                    default: 
                        return t('open');
                }
            }
            return t('open');

        case PathStatus.Closed:
            return t('closed');

        default:
            return '';
    }
};

const getStatusColor = (status: string, groomedAt?: string | null): string => {
    switch (status) {
        case PathStatus.Open:
            if (groomedAt) {
            const groomedState = getGroomedState(groomedAt);

            if (
                groomedState.state === GroomedState.Grooming ||
                groomedState.state === GroomedState.FreshlyGroomed ||
                groomedState.state === GroomedState.GroomedToday
            ) {
                return colors.neutralColor400;
            }
            }
            return colors.successColor500;

        case PathStatus.Closed:
            return colors.errorColor500;

        default:
            return colors.graytone5;
    }
};

const getPathCategoryIcon = (pathCategoryId: string, props?: any) => {
    switch (pathCategoryId) {
        case PathCategoryId.CrossCountrySkiing:
            return <PathCrossCountrySkiing {...props}/>;

        case PathCategoryId.Snowmobiling:
            return <PathSnowmobiling {...props}/>;

        case PathCategoryId.MountainBiking:
            return <PathMountainBiking {...props}/>;

        case PathCategoryId.Hiking:
            return <PathHiking {...props}/>;

        default:
            return <View {...props}></View>;
    }
};

const getPoiCategoryIcon = (poiCategoryId: string, props?: any) => {
    switch (poiCategoryId) {
        case PoiCategoryId.Lifts:
            return <PoiLifts {...props}/>;
        
        case PoiCategoryId.Missions:
            return <PoiMissions {...props}/>;

        case PoiCategoryId.Treasures:
            return <PoiTreasures {...props}/>;

        case PoiCategoryId.Restaurants:
        case PoiCategoryId.Cafes:
            return <PoiRestaurants {...props}/>;

        case PoiCategoryId.Attractions:
            return <PoiAttractions {...props}/>;

        case PoiCategoryId.Viewpoints:
            return <PoiViewpoints {...props}/>;

        case PoiCategoryId.Parkings:
            return <PoiParking {...props}/>;

        default:
            return <View {...props}></View>;
    }
};

export {
    formatLength,
    getDifficultyIcon,
    getDifficultyName,
    getStatusText,
    getStatusColor,
    getPathCategoryIcon,
    getPoiCategoryIcon,
};