import {
    PathDetailsContent,
    PoiDetailsContent,
    SnowmobileSubPathDetailsContent,
    useThemedStyleFunction,
    withExternalStyle,
    FeatureType,
    PathCategoryId,
} from "@miqmap/shared/src";
import { useCallback, useEffect } from "react";
import { View, ViewProps } from "react-native";
import { shallow } from "zustand/shallow";
import { useDataStore, useImageViewerStore, useStateStore } from "../../../stores";
import { Header } from "../..";
import { sideBarContentStyle } from "./side-bar-content.style";
import { getTranslatedProperty } from "../../../localization";
import { ScrollView } from "react-native-gesture-handler";

type SideBarDetailProps = {
    navigation?: any;
} & ViewProps;

const SideBarDetail = (props: SideBarDetailProps) => {
    const { style: styleProp, navigation } = props;
    
    const { selectedType, selectedId, setSelected } = useStateStore(state => state, shallow);
    const { paths, pathGpsCoordinates, pathCategories, pois, poiCategories, snowmobileSubPaths } = useDataStore(state => state, shallow);
    const { showImageViewer } = useImageViewerStore(state => state, shallow);

    const hasPathData = paths && pathGpsCoordinates && pathCategories;
    const hasPoiData = pois && poiCategories;
    const hasSnowmobileSubPathData = snowmobileSubPaths && pathCategories;

    // Remove current selection if we close this screen
    useEffect(() => {
        const unsubscribe = navigation.addListener('transitionStart', (event: any) => {
            if (event.data.closing) {
                setSelected(undefined, undefined);
            }
        });
        
        return unsubscribe;
    }, [navigation, setSelected]);

    // Get title for feature
    let title = '';
    switch (selectedType) {
        case FeatureType.Path:
            if (selectedId !== undefined && paths) {
                title = getTranslatedProperty('name', paths[selectedId]);
            }
            break;

        case FeatureType.Poi:
            if (selectedId !== undefined && pois) {
                title = getTranslatedProperty('name', pois[selectedId]);
            }
            break;

        case FeatureType.SnowmobileSubPath:
            if (selectedId !== undefined && snowmobileSubPaths) {
                title = getTranslatedProperty('name', snowmobileSubPaths[selectedId]);
            }
            break;

        default:
            break;
    }

    const handleOnBack = useCallback(() => {
        setSelected();
    }, [setSelected]);

    const handleOnImagePress = useCallback((images: string[], index: number) => {
        showImageViewer(images, index);
    }, [showImageViewer]);

    const style = useThemedStyleFunction(sideBarContentStyle);
    const containerStyle = withExternalStyle(style.brightContainer, styleProp);

    return (
        <View style={containerStyle}>
            <Header title={title} onBack={handleOnBack}/>
            <ScrollView>
                <View style={[style.marginTop, style.marginBottom]}>
                    { selectedType === FeatureType.Path && selectedId !== undefined && hasPathData &&
                        <PathDetailsContent {...paths[selectedId]}
                            onImagePress={handleOnImagePress}
                            gpsCoordinates={pathGpsCoordinates[selectedId]}
                            category={pathCategories[paths[selectedId].pathCategoryId]}
                            closestIndex={-1}
                        />
                    }
                    { selectedType === FeatureType.Poi && selectedId !== undefined && hasPoiData &&
                        <PoiDetailsContent {...pois[selectedId]}
                            onImagePress={handleOnImagePress}
                            category={poiCategories[pois[selectedId].poiCategoryId]}
                        />
                    }
                    { selectedType === FeatureType.SnowmobileSubPath && selectedId !== undefined && hasSnowmobileSubPathData &&
                        <SnowmobileSubPathDetailsContent {...snowmobileSubPaths[selectedId]}
                            category={pathCategories[PathCategoryId.Snowmobiling]}
                        />
                    }
                </View>
            </ScrollView>
        </View>
    );
};

export { SideBarDetail };