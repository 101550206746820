import React from 'react';
import { TouchableOpacity, View, ViewProps } from 'react-native';
import { FraListContentProps, PathListContentProps, PoiListContentProps } from '../../interfaces';
import { withExternalStyle } from '../../utils';
import { ListEntry } from '../list-entry';
import { useThemedStyleFunction } from '../theme-provider';
import { ThemedText } from '../themed-text';
import { listStyle } from './list.style';

interface DataProvider {
    entries: PathListContentProps[] | PoiListContentProps[] | FraListContentProps[],
    component: (props: any) => JSX.Element,
};

type ListProps = ViewProps & {
    emptyText: string;
    dataProviders: DataProvider[],
    onPress?: (providerIndex: number, entryIndex: number) => void,
};

const List = (props: ListProps) => {
    const {style: styleProp, emptyText, dataProviders, onPress} = props;

    const style = useThemedStyleFunction(listStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    const empty = dataProviders.length === 0 || dataProviders.every((provider) => provider.entries.length === 0);

    return (
        <View style={containerStyle}>
            { dataProviders.map((provider, providerIndex) => 
                provider.entries.map((entry, entryIndex) => (
                    <ListEntry key={`${providerIndex}${entryIndex}`}>
                        <TouchableOpacity onPress={() => onPress?.(providerIndex, entryIndex)}>
                            <provider.component {...entry} />
                        </TouchableOpacity>
                    </ListEntry>
                ))
            )}
            { empty ? <ThemedText>{ emptyText }</ThemedText> : null }
        </View>
    );
}

export { List };