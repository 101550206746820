export default {
    // Status
    open: 'Open',
    closed: 'Closed',
    groomedStatusCurrently: 'Grooming',
    groomedStatusPassed: 'Groomed ({{hoursPassed}} h)',
    groomedStatusYesterday: 'Groomed yesterday',
    groomedStatusWeekday: 'Groomed {{weekday}}',
    // Zone
    low: 'Low zone',
    high: 'High zone',
    // Difficulty
    veryEasy: 'Very easy',
    veryEasyPlural: 'Very easy',
    easy: 'Easy',
    easyPlural: 'Easy',
    medium: 'Medium',
    mediumPlural: 'Medium',
    hard: 'Hard',
    hardPlural: 'Hard',
    // Abbreviated weekdays
    abbreviatedMonday: 'mon',
    abbreviatedTuesday: 'tue',
    abbreviatedWednesday: 'wed',
    abbreviatedThursday: 'thu',
    abbreviatedFriday: 'fri',
    abbreviatedSaturday: 'sat',
    abbreviatedSunday: 'sun',
    // Measurements
    elevationAboveSeaLevel: 'm.a.s.l.',
    // Misc
    switchDirection: 'Switch direction',
    destinationNamePlaceholder: 'Järv Äventyr',
};